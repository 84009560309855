const mapper = new WeakMap()

export function useMutationObserver (callback, options = {}) {
  const observer = new MutationObserver(callback)
  if (!options?.keepAlive) {
    mapper.set(observer, function () {
      observer.disconnect()
      document.removeEventListener('KipuUnloaded', mapper.get(observer))
    })

    document.addEventListener('KipuUnloaded', mapper.get(observer))
  }
  return observer
}
