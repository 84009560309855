import { h, createApp } from 'vue'

export const RawHtml = (props, context) => {
  const template = `<div>${props.html.replace(/^(\s*<[a-zA-Z0-9]+)(\s|\/>)/, '$1 v-bind="$attrs" $2')}</div>`
  const nodes = createApp({
    beforeCreate () { this.$createElement = h },
    template,
  }, { ...context.attrs }).mount(document.createElement('div'))
  return nodes.$el.__vnode.children
}

RawHtml.props = ['html']

export default RawHtml
