<script setup>
import { ref } from 'vue'
import { useScroll } from '@vueuse/core'
import KButton from '@/components/kipu-ui/buttons/KButton'
import HeroIcon from '@/components/HeroIcon'
const Kipu = window.Kipu = window.Kipu || {}

const scrollTarget = ref(window)
const scroll = useScroll(scrollTarget, { behavior: 'smooth', throttle: 100 })
const { x: left, y: top } = scroll
scroll.left = left
scroll.top = top

const scrollTo = (position, ifScrolled) => {
  if (ifScrolled && !top.value) return
  if (!scrollTarget.value) return

  if (typeof position === 'object') {
    for (const key of ['top', 'left']) {
      if (position[key] !== undefined) scroll[key].value = position[key]
    }
  } else {
    top.value = Number(position) || 0
  }
}

Kipu.scrollTo = scrollTo
Object.defineProperty(Kipu, 'scrollTarget', {
  get: () => scrollTarget.value,
  configurable: true,
  enumerable: true,
})

for (const name of ['Left', 'X', 'Top', 'Y']) {
  const prop = name.toLowerCase()
  const key = `scroll${name}`
  const v = scroll[prop]
  Object.defineProperty(Kipu, key, {
    get: () => v.value,
    set: (value) => { v.value = Number(value) || 0 },
    configurable: true,
    enumerable: true,
  })
}

/*
NOTE: "arrivedState" isn't set properly on mount
Just use top.value
*/
</script>
<template>
  <KButton
    v-if="top"
    mode="special"
    :disabled="top === 0"
    class="tw-fixed tw-bottom-4 tw-left-4 tw-z-1000 print:tw-hidden"
    :data-scroll="top"
    @click="scrollTo(0)"
  >
    <HeroIcon
      class="tw-w-4 tw-h-4 tw-mr-2"
      icon="arrow-up-circle"
      outline
    />
    Back to Top
  </KButton>
</template>
