import { defineStore } from 'pinia'
import { computed, ref, shallowReactive } from 'vue'
import { v4 as uuid } from 'uuid'

export const useAppSlideoutStore = defineStore('AppSlideoutStore', () => {
  const parent = ref(null)
  const drawers = shallowReactive({})
  const isOpen = computed(() => Object.values(drawers).some(drawer => drawer.isOpen))
  const registerDrawer = (upstream) => {
    const key = generateKey()
    const drawer = shallowReactive(upstream || { isOpen: false, key })
    drawer.key = key
    drawers[key] = drawer
    return drawer
  }
  const generateKey = () => uuid()
  const removeDrawer = drawerOrKey => {
    if (drawerOrKey?.key) drawerOrKey = drawerOrKey.key
    const drawer = drawers[drawerOrKey]
    if (drawer) drawer.isOpen = false
    delete drawers[drawerOrKey]
  }

  return {
    drawers,
    isOpen,
    parent,
    registerDrawer,
    removeDrawer,
  }
})
