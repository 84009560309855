const emptyFunc = () => {}

const logger = isDev ? console.log : console.debug

export const debug = isDev ? console.debug : emptyFunc
logger.debug = debug

export const info = isDev ? console.info : emptyFunc
logger.info = info

export const warn = isDev ? console.warn : emptyFunc
logger.warn = warn

export const error = console.error
logger.error = error

export const log = logger
logger.log = log
window.logger = logger

export { logger }
