<script setup>
import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue'
import { useLastActivityStore } from '@/apps/emr/stores/LastActivityStore'
import KButton from '@/components/kipu-ui/buttons/KButton'
import SignInForm from '@/apps/emr/components/SignInForm'

const lastActivity = useLastActivityStore()
const { keepWorking, signOut, enableBrowserNotice } = lastActivity
enableBrowserNotice()

const {
  timeLeft,
  isChanged,
  isOpen,
  isSigningOut,
  isUnstable,
  isUnauthorized,
} = storeToRefs(lastActivity)

const countdownSeconds = computed(() => Math.max(Math.floor((((timeLeft.value) / 60) % 1) * 60) || 0, 0))
const countdownMinutes = computed(() => Math.max(Math.floor(timeLeft.value / 60) || 0, 0))
const dialogOpen = computed(() => !!(isOpen.value || isSigningOut.value || isUnstable.value || isUnauthorized.value || isChanged.value))

watch(dialogOpen, (newValue) => {
  if (newValue) {
    document.querySelector('main').classList.add('tw-blur-md')
  } else {
    document.querySelector('main').classList.remove('tw-blur-md')
  }
})
</script>

<template>
  <Dialog
    as="div"
    :open="dialogOpen"
    class="tw-relative tw-z-50"
    @close="!isUnauthorized && !isChanged && keepWorking()"
  >
    <div class="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-p-4 tw-bg-black/30">
      <DialogPanel
        v-if="isChanged"
        class="tw-w-full tw-max-w-sm tw-h-25 tw-rounded tw-bg-white tw-p-6 tw-flex tw-flex-col tw-gap-2"
      >
        <DialogTitle class="tw-text-2xl">
          The logged in user has changed.
        </DialogTitle>

        <p>
          For Security Reasons This Page Will Be Refreshed
        </p>
      </DialogPanel>
      <DialogPanel
        v-else-if="isUnauthorized"
        class="tw-w-full tw-max-w-sm tw-h-25 tw-rounded tw-bg-white tw-p-6 tw-flex tw-flex-col tw-gap-2"
      >
        <DialogTitle class="tw-text-2xl">
          Your Session Is Invalid
        </DialogTitle>
        <DialogDescription class="tw-bg-red-100 tw-rounded tw-p-4 tw-mb-2">
          Please Login Again to Continue
        </DialogDescription>
        <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-1">
          <p>
            For Security Reasons You Need to Reauthenticate
          </p>
          <p>
            Click the button below to login again. If you don't, you will be signed out within 60 seconds.
          </p>

          <SignInForm />
        </div>
      </DialogPanel>
      <DialogPanel
        v-else-if="isSigningOut"
        class="tw-w-full tw-max-w-sm tw-h-25 tw-rounded tw-bg-white tw-p-6 tw-flex tw-flex-col tw-gap-2"
      >
        <DialogTitle class="tw-text-2xl">
          Sign Out Has Been Triggered
        </DialogTitle>
        <DialogDescription class="tw-bg-red-100 tw-rounded tw-p-4 tw-mb-2">
          You will be signed out within 5 seconds.
        </DialogDescription>
        <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-1">
          <p>
            Not Ready?
          </p>

          <KButton
            mode="secondary"
            @click="keepWorking()"
          >
            Click Here to Keep Working
          </KButton>
        </div>
      </DialogPanel>
      <DialogPanel
        v-else
        class="tw-w-full tw-max-w-sm tw-h-25 tw-rounded tw-bg-white tw-p-6 tw-flex tw-flex-col tw-gap-2"
      >
        <DialogTitle class="tw-text-2xl">
          <template v-if="isOpen">
            Session Timeout Warning
          </template>
          <template v-else>
            Network Instability Warning
          </template>
        </DialogTitle>
        <DialogDescription class="tw-bg-red-100 tw-rounded tw-p-4">
          <template v-if="isOpen">
            You're about to be signed out due to inactivity.
          </template>
          <template v-if="isUnstable">
            <p>
              We were unable to fetch your session information.
            </p>
            <p>
              The system may be experiencing an outage,
              or your internet connection may not be stable.
            </p>
            <p>
              To avoid losing data, we recommend you write down any unsaved information.
            </p>
          </template>
        </DialogDescription>

        <p v-if="isUnstable">
          Please click the <strong>Keep Working</strong> button to continue.
        </p>
        <p v-else>
          If you would like more time, please click the <strong>Keep Working</strong> button.
          If you don't, you will be signed out in:
        </p>
        <h3 v-if="isOpen">
          {{ countdownMinutes }}:{{ countdownSeconds < 10 ? '0' : '' }}{{ countdownSeconds }}
        </h3>
        <div class="tw-flex tw-justify-around">
          <KButton
            mode="secondary"
            @click="signOut('idle_timeout')"
          >
            Sign Out Now
          </KButton>
          <KButton @click="keepWorking()">
            Keep Working
          </KButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>
