/*
use like so:
<HeroIcon icon="plus-circle" class="w-5 h-5 text-blue-500" />
<HeroIcon icon="plus-circle" outline />
*/

import { h } from 'vue'
import { computedAsync } from '@vueuse/core'
import { fetchHeroIcon } from '@/lib/utils/HeroIcons'
import RawHtml from '@/components/RawHtml'

export default {
  name: 'HeroIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    wrapper: {
      type: [String, Object],
      default: 'div',
    },
    hideOnError: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { attrs }) {
    const content = computedAsync(async () => await fetchHeroIcon(props), '')

    return () => {
      if (!content.value && props.hideOnError) {
        return null
      }

      const wrapper = props.wrapper === 'raw' ? RawHtml : props.wrapper
      if (props.wrapper === 'raw' || props.wrapper === RawHtml) {
        return h(
          RawHtml,
          {
            ...attrs,
            html: content.value,
          },
        )
      } else {
        return h(wrapper, { ...attrs, innerHTML: content.value })
      }
    }
  },
}
