<script setup>
import { ref } from 'vue'
import { customFetch } from '@/lib/utils/Fetch/Base'
import { useLastActivityStore } from '@/apps/emr/stores/LastActivityStore'
import KButton from '@/components/kipu-ui/buttons/KButton'

const { keepWorking } = useLastActivityStore()
const isSubmitting = ref(false)
const error = ref(null)

const runSignIn = async (event) => {
  event.preventDefault()
  isSubmitting.value = true
  try {
    const response = await customFetch(event.target.action, {
      method: 'POST',
      body: new FormData(event.target),
      json: true,
      multipart: true,
    })

    const { success } = await response.json()
    if (success) {
      keepWorking()
      error.value = []
    } else {
      throw new Error('Could not authenticate user. If you continue to have trouble, please contact the designated Super Administrator for your facility.')
    }
  } catch (err) {
    logger.error(err)
    error.value = 'Could not authenticate user. If you continue to have trouble, please contact the designated Super Administrator for your facility.'
  } finally {
    isSubmitting.value = false
  }
}
</script>

<template>
  <form
    autocomplete="login"
    class="tw-w-full"
    action="/users/sign_in.json"
    accept-charset="UTF-8"
    method="post"
    @submit="runSignIn($event)"
  >
    <div
      v-if="error"
      class="tw-bg-red-100 tw-rounded tw-p-4 tw-mb-2"
    >
      <p class="tw-text-red-700 tw-text-sm tw-mb-1">
        {{ error }}
      </p>
    </div>
    <input
      name="utf8"
      type="hidden"
      value="✓"
    >
    <input
      autofocus="autofocus"
      autocomplete="username"
      placeholder="Email or Username"
      class="tw-text-center tw-w-full tw-border tw-rounded tw-mb-4 tw-min-h-[50px]"
      type="text"
      name="user[login]"
    >
    <input
      autocomplete="current-password"
      placeholder="Password"
      class="tw-text-center tw-w-full tw-border tw-rounded tw-my-4 tw-min-h-[50px]"
      type="password"
      name="user[password]"
    >
    <KButton
      type="submit"
      :disabled="isSubmitting"
    >
      Sign In
    </KButton>
  </form>
</template>
