import {
  StorageSerializers,
  useStorage,
} from '@vueuse/core'

export function useLocalStorageListeners () {
  const activityStorage = useStorage('kipu-last-activity', 0, null, { serializer: StorageSerializers.number })
  const isOpen = useStorage('kipu-session-warning-open', false, null, { serializer: StorageSerializers.boolean })
  const isSigningOut = useStorage('kipu-is-signing-out', '', null, { serializer: StorageSerializers.string })
  const currentUser = useStorage('kipu-current-user-id', null, null, { serializer: StorageSerializers.number })

  return {
    isOpen,
    activityStorage,
    isSigningOut,
    currentUser,
  }
}
