<script setup>
import {
  defineAsyncComponent,
  inject,
  onMounted,
  ref,
  watch,
} from 'vue'
import BackToTopButton from '@/components/kipu-ui/buttons/BackToTopButton'
import ErrorBoundary from '@/components/ErrorBoundary'
import PermissionRequest from '@/components/notifications/PermissionRequest'
import SlideoutSingleton from './SlideoutSingleton'
import TimeoutModal from '@/components/notifications/TimeoutModal'
import MarsManager from '@/apps/mars/components/MarsManager/TabWatcher'
import { useDosespotCounterStore } from '@/apps/dosespot/stores/DosespotCounterStore'
import { useLocalStorageListeners } from '@/composables/useLocalStorageListeners'

const dosespotCounterStore = useDosespotCounterStore()
const { currentUser } = useLocalStorageListeners()

const GenerativeAiTeleporter = defineAsyncComponent(() => import('@/apps/generative-ai/components/GenerativeAiTeleporter'))
const PdfPackage = defineAsyncComponent(() => import('@/apps/printable/components/PdfPackage'))

const hasGenAiComponent = () => !!document.querySelector('[data-teleport="generative-ai-component"]')
const isSessionPage = () => /\/sign[_-](in|out)$/.test(new URL(location).pathname)

const enableGenAi = ref(hasGenAiComponent())
const enableNewPdfPackage = inject('enableNewPdfPackage')
const enableTimeout = ref(!window.disableSessionTimeout && !isSessionPage())

if (isSessionPage()) currentUser.value = 0

watch(currentUser, (newValue) => {
  if (isSessionPage() && newValue) {
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }
}, { immediate: true })

onMounted(() => {
  dosespotCounterStore.onPageMount()
  if (!enableGenAi.value) enableGenAi.value = hasGenAiComponent()
})
</script>

<template>
  <ErrorBoundary v-if="enableTimeout">
    <PermissionRequest />
  </ErrorBoundary>
  <ErrorBoundary v-if="enableTimeout">
    <TimeoutModal />
  </ErrorBoundary>
  <ErrorBoundary v-if="enableNewPdfPackage">
    <Suspense>
      <PdfPackage />
    </Suspense>
  </ErrorBoundary>
  <ErrorBoundary>
    <BackToTopButton />
  </ErrorBoundary>
  <ErrorBoundary v-if="enableGenAi">
    <Suspense>
      <GenerativeAiTeleporter />
    </Suspense>
  </ErrorBoundary>
  <ErrorBoundary>
    <SlideoutSingleton />
  </ErrorBoundary>
  <ErrorBoundary>
    <MarsManager />
  </ErrorBoundary>
</template>
