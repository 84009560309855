import Cookie from 'universal-cookie'

export function useSessionTimeout (cookies) {
  /*
    The amount of time in seconds that the user must be idle before
    being logged out. This should be set to the same value as the
    session timeout on the server, but can be overridden for specific
    roles. The server default is 15 minutes. We will fallback to the
    absolute minimum of 60 seconds if the value is unset or invalid.
  */
  const getSessionTimeout = () => {
    return Math.max(Number(cookies.get('KIPU_SESSION_TIMEOUT_LIMIT')) || 0, 0)

    // if (Number(cookies.get('KIPU_SESSION_TIMEOUT_LIMIT'))) {
    //   return Math.max(Number(cookies.get('KIPU_SESSION_TIMEOUT_LIMIT')), 60)
    // } else if (Number(window.sessionTimeoutSeconds)) {
    //   return Math.max(Number(window.sessionTimeoutSeconds), 60)
    // } else {
    //   return 60
    // }
  }

  /*
    getIdleInterval is how long the user should have left in their session
    before we start showing a warning that they are about to be logged out.
    This should be set to a value that is high enough that it allows for
    multiple attempts to ping the server before the session times out, but
    low enough that we don't just pop a warning up as soon as the user is
    idle. The smallest session timeout that we will allow is 60 seconds, so
    we will set the interval to be 30 seconds at absolute minimum (unlikely).
  */
  const getIdleInterval = () => Math.min(180, Math.ceil(getSessionTimeout() * 0.5))

  /*
    getPingInterval is the number of milliseconds at which we will throttle
    pings to reset the current session timeout. This should be set to a
    value that's low enough that it allows for multiple attempts to ping
    the server before the session times out, but high enough that we don't
    overload the server just pinging it. The smallest session timeout that
    we will allow is 60 seconds, so we will set the interval to be 30 seconds
    at absolute minimum (unlikely).

    this value is also automatically shortened at ping time to be no more
    than the difference between the session left and the idle check interval
  */
  const getPingInterval = () => Math.min(60, Math.ceil(getSessionTimeout() * 0.5)) * 1000

  return {
    getSessionTimeout,
    getIdleInterval,
    getPingInterval,
  }
}

/* Raw Helpers for Session Data outside of Vue */
export function getSessionTimeout () {
  return useSessionTimeout(new Cookie()).getSessionTimeout()
}

export function getIdleInterval () {
  return useSessionTimeout(new Cookie()).getIdleInterval()
}

export function getPingInterval () {
  return useSessionTimeout(new Cookie()).getPingInterval()
}
