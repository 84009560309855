import { defineStore } from 'pinia'

import { v4 as uuid } from 'uuid'
import { computed, nextTick, ref, watch } from 'vue'
import Cookie from 'universal-cookie'
import { useCookies } from '@vueuse/integrations/useCookies'
import {
  StorageSerializers,
  useIdle,
  useTimestamp,
  useStorage,
  useWindowFocus,
  useFetch,
} from '@vueuse/core'
import { useWebNotification } from '@/composables/useWebNotification'
import { usePostMessageListener } from '@/composables/usePostMessageListener'
import { useSessionTimeout } from '@/composables/useSessionTimeout'
import { useSignoutCall } from '@/composables/useSignoutCall'
import { useLocalStorageListeners } from '@/composables/useLocalStorageListeners'
import notificationLogo from '@/assets/images/notification-logo.png'

const checkSessionPath = '/users/sessions/check'
const pingSessionPath = '/users/sessions/ping'

export const useLastActivityStore = defineStore('LastActivityStore', () => {
  const tabId = uuid()
  /*
  idle rate is how long the tab must sit idle before it is actually considered
  to be "idle". This can be set to a relatively low value, since we will be
  tracking when the user was last active. For an accurate session timeout, we
  should ping the server as soon as they are idle. For performance reasons, we
  don't want to ping the server too often, so we will throttle resets.
  */
  const { lastActive } = useIdle(1000)
  const currentTime = useTimestamp({ interval: 1000 })
  const universalCookies = new Cookie()
  const cookies = useCookies(['KIPU_SESSION_TIMEOUT_LIMIT', 'KIPU_SESSION_TIMEOUT_SECONDS', 'KIPU_LAST_INSTANCE_ACTIVITY'], { autoUpdateDependencies: true }, universalCookies)
  const focused = useWindowFocus()

  const updateCookies = () => {
    Object.call(universalCookies, universalCookies._updateBrowserValues || universalCookies.update)
  }

  const {
    getSessionTimeout,
    getIdleInterval,
    getPingInterval,
  } = useSessionTimeout(cookies)

  const {
    activityStorage,
    currentUser,
    isOpen,
    isSigningOut,
  } = useLocalStorageListeners()
  isOpen.value = false
  activityStorage.value = lastActive.value
  currentUser.value = window.Kipu?.user?.id
  isSigningOut.value = ''

  const networkFailures = ref(0)
  const stabilityAcknowledged = ref(null)

  const disabled = computed(() => !!window.disableSessionTimeout || (false && !currentUser.value))
  const idleFor = computed(() => Math.floor((currentTime.value - activityStorage.value) / 1000) + 2)
  const isChanged = computed(() => isSigningOut.value === 'user_changed')
  const isUnauthorized = computed(() => isSigningOut.value === 'user_not_authorized')
  const isUnstable = computed(() => !stabilityAcknowledged.value && networkFailures.value >= 3)
  const shouldClose = computed(() => !signOut.isSigningOut.value && isOpen.value && timeLeft.value > getIdleInterval())
  const shouldOpen = computed(() => timeLeft.value <= getIdleInterval() && !isOpen.value)
  const timeLeft = computed(() => Math.max(getSessionTimeout() - idleFor.value - 5, 0))

  let warningClose = () => {}
  let showSignedOut = () => {}
  const browserNoticeEnabled = false

  const enableBrowserNotice = () => {
    if (browserNoticeEnabled) return
    const {
      show: showWarning,
      close: warnClose,
      onClick: onWarningClick,
    } = useWebNotification({
      title: 'Kipu Inactivity Warning',
      body: "You're about to be signed out of Kipu. Click here to keep working.",
      dir: 'auto',
      icon: notificationLogo,
      lang: 'en',
      renotify: true,
      requireInteraction: true,
      tag: 'idle-warning',
      vibrate: [500, 100, 200, 100, 300, 100, 500],
    })
    warningClose = warnClose

    const {
      show,
      onClick,
    } = useWebNotification({
      title: 'Kipu Sign Out Notice',
      body: 'You were signed out of Kipu due to inactivity. Please login again to continue.',
      dir: 'auto',
      icon: notificationLogo,
      lang: 'en',
      renotify: true,
      requireInteraction: false,
      tag: 'signed-out',
      closeOnUnload: false,
    })

    showSignedOut = show
    onClick(() => {
      window.focus()
    })

    onWarningClick(() => {
      warningClose()
      if (isUnauthorized.value) {
        window.focus()
      } else {
        keepWorking()
      }
    })

    watch(isOpen, (newValue) => {
      if (!newValue) warningClose()
      else if (!focused.value) {
        if (isUnauthorized.value) {
          warningClose()
          showSignedOut()
        } else {
          showWarning()
        }
      }
    })
  }

  const signOut = useSignoutCall({
    defaultReason: 'idle_timeout',
    beforeSignout () {
      isSigningOut.value = signOut.isSigningOut.value
      setIsOpen(true)
    },
    onSignout () {
      lastActive.value = activityStorage.value = currentTime.value - (getSessionTimeout() * 1000)
      if (!focused.value) showSignedOut()
      warningClose()
    },
    onCancel () {
      setIsOpen(false)
    },
  })

  function setIsOpen (value) {
    if (value && window.Kipu?.formIsDirty) window.Kipu.onFormInputChange()
    if (!value) {
      isSigningOut.value = ''
      warningClose()
    }

    isOpen.value = value
  }

  function keepWorking () {
    lastActive.value = activityStorage.value = currentTime.value
    fetchable.ping(true)
    signOut.cancel()
    setIsOpen(false)
    warningClose()
    stabilityAcknowledged.value = !!networkFailures.value
  }

  /*
    I originally kept check and ping refs as individual top level items
    but it started to become very verbose to set conditions for preventing
    duplicated fetch calls and preventing calls from multiple tabs at once.

    After reorganizing into a contained class, a lot of light was shed on
    some underlying issues with the way that the session timeout was being
    handled. This is a much more robust solution that should be easier to
    maintain and extend in the future.

    I still want to come back and refactor, try to come up with a simpler manager
    but this is a good start.

    A key factor in this particular commit is I was able to track down, that
    the dynamic `KIPU_SESSION_TIMEOUT_SECONDS` cookie can actually make more
    problems if used as a "session length" value. It is much better to use
    as a "session left" value, and also include a static cookie that contains
    the full session length. This allows us to accurately track the session
    and also allows us to use the dynamic cookie to reset the session length
  */
  const fetchable = new (class FetchTracking {
    constructor () {
      this._running = useStorage('kipu-session-checking', null, null, { serializer: StorageSerializers.object })
      this._sessionStarted = useStorage('kipu-session-started', null, null, { serializer: StorageSerializers.number })
      this._running.value = null
      this._sessionStarted.value = +currentTime.value

      this.pingHandle = this.createFetch(pingSessionPath, 'ping')
      this.checkHandle = this.createFetch(checkSessionPath, 'check')
      networkFailures.value = 0

      /*
        Run single ping on first boot. This helps compensate for variable
        page load times. This _could_ be done with a "check" call, but it's
        better to set a fresh session time on first load.

        Without this, pages like Occupancy set session timer before loading
        all their extended data. ping is a short efficient route that avoids
        extended network load times.

        This might also be possible to optimize on the server side level
        using an around action, but it would mean more warden complexity
      */
      this.ping(true)
    }

    setCurrentSignoutState () {
      nextTick(() => {
        if (isUnauthorized.value && timeLeft.value) return keepWorking()

        if (timeLeft.value <= 0) !signOut.isSigningOut.value && signOut()
        else if (!isOpen.value && timeLeft.value <= getIdleInterval()) setIsOpen(true)
      })
    }

    afterFetch (ctx) {
      networkFailures.value = 0
      stabilityAcknowledged.value = false
      updateCookies()

      if (ctx.data?.recalculate) {
        this.sessionStartedFromCookie()

        const maxActivity = Math.max(
          activityStorage.value,
          this.sessionStarted,
          Number(cookies.get('KIPU_LAST_INSTANCE_ACTIVITY')) * 1000 || 0,
        )

        activityStorage.value = maxActivity
        if (lastActive.value < maxActivity) lastActive.value = maxActivity

        this.setCurrentSignoutState()
      } else if (ctx.data?.success && ctx.data.timeout_in > 0) {
        if (Number(ctx.data.timestamp)) {
          this.sessionStarted = Number(ctx.data.timestamp) * 1000
        } else if (ctx.data.timeout) {
          this.sessionStarted = (ctx.data.timeout - ctx.data.timeout_in) * 1000
        } else if (Number(cookies.get('KIPU_SESSION_TIMEOUT_SECONDS'))) {
          this.sessionStartedFromCookie()
        } else if (!this.soft) {
          this.sessionStarted = +new Date()
        }

        const maxActivity = Math.max(
          activityStorage.value,
          this.sessionStarted,
          Number(ctx.data.timestamp) * 1000 || 0,
          Number(cookies.get('KIPU_LAST_INSTANCE_ACTIVITY')) * 1000 || 0,
        )

        activityStorage.value = maxActivity
        if (lastActive.value < maxActivity) lastActive.value = maxActivity

        this.setCurrentSignoutState()
      } else if (ctx.data?.success === false && ctx.data.user_id === null) {
        if (!isUnauthorized.value) signOut('user_not_authorized')
      }

      if (ctx.data?.user_id) currentUser.value = Number(ctx.data.user_id)

      return ctx
    }

    createFetch (path, type) {
      const fetcher = useFetch(path, this[`${type}Options`]?.() || {}).get().json()
      fetcher.onFetchFinally(() => nextTick(() => this.deactivate()))
      const lastFetchTimes = []
      const minimumInterval = 5 * 1000
      const proxy = {
        ...fetcher,
        execute () {
          /*
            Bucket throttling to let multiple requests go through
            until the bucket is full. This is a simple way to allow
            quick rechecks without overloading the server
          */

          if (getSessionTimeout() >= 60) {
            lastFetchTimes.push(currentTime.value)
            return fetcher.execute()
          }

          let idx = lastFetchTimes.length
          while (idx--) {
            if (currentTime.value - lastFetchTimes[idx] > minimumInterval) {
              lastFetchTimes.splice(idx, 1)
            }
          }

          if (lastFetchTimes.length < 4) {
            lastFetchTimes.push(currentTime.value)
            return fetcher.execute()
          } else {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                proxy.execute().then(resolve).catch(reject)
              }, 100)
            })
          }
        },
      }
      return proxy
    }

    deactivate () {
      if (this.active && this.ready) this.running = null
    }

    isFetchFailure (ctx) {
      return ctx.response?.status === 500 || /Failed to fetch/.test(ctx.error?.toString?.())
    }

    onFetchError (ctx) {
      logger.debug(ctx)
      if (this.isFetchFailure(ctx)) {
        if (timeLeft.value <= 0) {
          signOut.now('network_unreachable')
        } else {
          networkFailures.value++
        }
      } else if (!ctx.data?.success) {
        networkFailures.value = 0
        if (ctx.data === null || /aborted/i.test(ctx.error)) return ctx

        if (!isUnauthorized.value && ctx.data?.success === false && ctx.data.user_id === null) {
          signOut('user_not_authorized')
        }
      }

      return ctx
    }

    abort () {
      if (this.checkHandle?.canAbort?.value) this.checkHandle.abort()
    }

    /*
      queue is a function that will be called when the user presents
      new activity. This allows us to accurately guage exactly how long
      the user has been idle by resetting their timeout count on the
      server side without overloading the server with constant pings.
      because of the way that cookies and sessions work we could even
      theoretically split the ping server into a dedicated service separate
      from the EMR, but that's a bit overkill for now.
    */
    queue () {
      updateCookies()

      if (this.isChanged || this.isUnauthorized) return false

      if (this.timeout) clearTimeout(this.timeout)
      if (this.waitFor <= 5) {
        this.ping()
      } else {
        this.timeout = setTimeout(() => {
          this.timeout = null
          this.ping()
        }, this.waitFor)
      }
    }

    check () {
      updateCookies()

      if (this.checkable) {
        /* TODO: Come back to this later. It's been running without for months */
        // this.lastCheck = this.afterHours
        this.running = { id: tabId, soft: true }
        this.abort()
        this.checkHandle.execute()
      }
    }

    get checkable () {
      return !this.running // && !this.lastCheck
    }

    get checking () {
      return !!this.checkHandle?.isFetching?.value
    }

    checkOptions () {
      return {
        immediate: false,
        afterFetch: this.afterFetch.bind(this),
        onFetchError: this.onFetchError.bind(this),
      }
    }

    ping (force = false) {
      if (disabled.value || isChanged.value || isUnauthorized.value) {
        this.abort()
        return Promise.resolve({ success: false })
      } else if (force || (this.pingable && this.ready)) {
        this.running = { id: tabId }
        this.abort()
        return this.pingHandle.execute(force).then(() => this.pingHandle.data.value)
      }
    }

    get pingable () {
      return this.waiting || this.soft
    }

    get pinging () {
      return this.pingHandle?.isFetching?.value
    }

    pingOptions () {
      return Object.assign(
        this.checkOptions(),
        {
          onFetchError: (ctx) => {
            if (this.isFetchFailure(ctx)) this.queue()
            return this.onFetchError(ctx)
          },
          async beforeFetch ({ options }) {
            options.headers = {
              ...options.headers,
              IDLE_FOR_SECONDS: idleFor.value,
            }

            return { options }
          },
        },
      )
    }

    get active () {
      return this.running?.id === tabId
    }

    get afterHours () {
      return timeLeft.value < 0
    }

    get busy () {
      return this.pinging || this.checking
    }

    get ready () {
      return !this.busy
    }

    get running () {
      return this._running.value
    }

    set running (newValue) {
      this._running.value = newValue
    }

    get sessionFor () {
      return (currentTime.value - this.sessionStarted) / 1000
    }

    get sessionLeft () {
      return getSessionTimeout() - this.sessionFor
    }

    get sessionStarted () {
      return this._sessionStarted.value
    }

    set sessionStarted (newValue) {
      this._sessionStarted.value = newValue
    }

    sessionStartedFromCookie () {
      const cookieTimeoutSeconds = Number(cookies.get('KIPU_SESSION_TIMEOUT_SECONDS')) || 0
      if (cookieTimeoutSeconds) {
        const sessionForSeconds = Math.max(getSessionTimeout() - cookieTimeoutSeconds, 0) || 0

        this.sessionStarted = currentTime.value - (sessionForSeconds * 1000)
      }
    }

    get soft () {
      return this.running?.soft
    }

    get waitFor () {
      if (this.isUnauthorized) return 60
      return Math.min(getPingInterval(), (this.sessionLeft - (getIdleInterval() + 10)) * 1000)
    }

    get waiting () {
      return !this.active
    }

    get networkFailures () {
      return networkFailures.value
    }

    set networkFailures (newValue) {
      networkFailures.value = newValue
    }
  })()

  // ********************* Watchers ***********************************
  usePostMessageListener((event) => {
    if (event?.data?.type === 'ajax_request_complete') {
      fetchable.afterFetch(event)
    } else if (event?.data?.type === 'kipu_ping_user') {
      const promise = fetchable.ping() || Promise.resolve({ success: false })
      if (event.data.callback) {
        promise
          .then((response) => postMessage({ type: event.data.callback, response }))
          .catch((error) => postMessage({ type: event.data.callback, response: { success: false, error: error?.message } }))
      }
    }
  })

  watch(timeLeft, () => {
    if (timeLeft.value % 15 === 0) updateCookies()
    if (disabled.value) return
    const cookieValue = Number(cookies.get('KIPU_LAST_INSTANCE_ACTIVITY') || 0) * 1000
    if (cookieValue && cookieValue > activityStorage.value) {
      activityStorage.value = cookieValue
    } else if (isSigningOut.value) {
      setIsOpen(true)
    } else if (shouldClose.value) {
      setIsOpen(false)
    } else if (shouldOpen.value || timeLeft.value <= 0) {
      fetchable.check()
    }
  })

  watch(lastActive, (newValue) => {
    if (newValue > activityStorage.value && !isOpen.value) {
      activityStorage.value = newValue
      fetchable.queue()
    }
  })

  watch(isSigningOut, (newValue) => {
    updateCookies()

    if (signOut.isSigningOut.value === 'user_changed') {
      isSigningOut.value = 'user_changed'
    } else if (!newValue) {
      if (signOut.isSigningOut.value) signOut.cancel()
    } else if (signOut.isSigningOut.value !== newValue) {
      signOut(newValue)
    }
  })

  watch(signOut.isSigningOut, (newValue) => {
    updateCookies()
    if (newValue && newValue !== isSigningOut.value) {
      isSigningOut.value = newValue
    }
  })

  watch(currentUser, (newValue) => {
    if (newValue) {
      if (window.Kipu?.user?.id && newValue !== window.Kipu?.user?.id) signOut('user_changed')
      else fetchable.queue()
    } else if (newValue === 0) {
      fetchable.ping(true)
    }
  })

  watch(disabled, (isDisabled) => isDisabled ? fetchable.abort() : fetchable.check())

  return {
    addActivity: () => fetchable.queue(),
    enableBrowserNotice,
    isChanged,
    isOpen,
    isSigningOut,
    isUnstable,
    isUnauthorized,
    keepWorking,
    lastActive,
    signOut,
    timeLeft,
    updateCookies,
  }
})
