import { customFetch } from '@/lib/utils/Fetch/Base'
import { ref, watch } from 'vue'
import { defineStore } from 'pinia'

export const useDosespotCounterStore = defineStore('DosespotCounterStore', () => {
  const count = ref(null)
  const currentInterval = ref(null)
  const isLoaded = ref(false)
  const isLoading = ref(null)

  const loadCount = async (refresh = false) => {
    if (isLoading.value) return await isLoading.value
    let resolve
    isLoading.value = new Promise(_resolve => { resolve = _resolve })
    try {
      const response = await customFetch(`/api/emr/erx/dosespot/notification_count?refresh=${refresh}`)
      const result = await response.json()
      count.value = result.count
      isLoaded.value = true
    } catch (err) {
      console.error('Failed to load dosespot count:', err)
      // return { path: '/users/sign_in' }
    }
    isLoading.value = null
    resolve()
  }

  const onPageMount = () => {
    const el = document.querySelector('.dosespot-notification-counter')
    if (el) {
      count.value = Number(el.innerText)
      currentInterval.value = setInterval(() => loadCount(false), 60 * 1000)
    }
  }

  watch(count, (newValue, oldValue) => {
    if (!isLoaded.value) return
    if (newValue === oldValue) return

    if (oldValue !== null && newValue !== null) {
      clearInterval(currentInterval.value)
      currentInterval.value = null
    };

    [...document.querySelectorAll('.dosespot-notification-counter')].forEach(el => {
      el.innerText = count.value || 0
    })
  })

  return {
    onPageMount,
    loadCount,
    count,
    isLoaded,
  }
})
