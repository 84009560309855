<script setup>
import { defineProps, defineOptions, onErrorCaptured, ref, useAttrs } from 'vue'
const attrs = useAttrs()

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  propagate: {
    type: Boolean,
    default: false,
  },
})

const error = ref(null)
const instance = ref(null)
const details = ref(null)
const devEnv = isDev

onErrorCaptured((err, vm, info) => {
  logger.error('[ErrorBoundary]', err, vm, info, props.propagate)
  error.value = err
  instance.value = vm?.$options && JSON.stringify(vm.$options)
  details.value = info

  return !!props.propagate
})
const reset = () => {
  logger.debug('[ErrorBoundary]', 'Resetting error')
  error.value = null
  instance.value = null
  details.value = null
}
defineExpose({ error, instance, details, reset })
</script>
<template>
  <slot
    v-if="error"
    name="error"
    :error="error"
    :instance="instance"
    :details="details"
    :reset="reset"
  >
    <div
      class="tw-hidden"
      data-error-debug="true"
      aria-hidden="true"
    >
      Something went wrong ({{ details }} | {{ instance }}). Check the console for more info:
      <pre>{{ error }}</pre>
      <hr>
      <button
        v-if="devEnv"
        @click="reset"
      >
        Reset Error
      </button>
    </div>
  </slot>
  <slot
    v-else
    v-bind="attrs"
  />
</template>
