/*
  Guarantee that `signOut` can only be called once. This is to prevent
  redirect loops from happening once time left gets below 0. this we we
  can call signOut multiple times, but it will only ever redirect once.
*/
import { computed, ref } from 'vue'
import { usePostMessageListener, usePostMessage } from './usePostMessageListener'

const logoutPath = '/users/sign_out'
const callbacks = []

function isSignInPage () {
  return /\/sign_in$/.test(new URL(location).pathname)
}

function buildSignout () {
  if (window.kipuGlobalSignOutHandler) {
    return window.kipuGlobalSignOutHandler
  }
  let timeout, triggerSignout

  const post = usePostMessage()
  const eventFrom = (event) => {
    if (typeof event === 'string') {
      event = { reason: event }
    }
    return event
  }
  window.kipuGlobalSignOutHandler = (reason, ...args) => {
    post('kipu_signout', { args, ...eventFrom(reason) })
  }

  const signOut = window.kipuGlobalSignOutHandler

  const isSigningOut = ref(null)
  const isParentWindow = window.top === window.self

  signOut.isSigningOut = computed(() => isSigningOut.value)
  signOut.cancel = () => {
    if (isSigningOut.value === 'user_changed') return false
    clearTimeout(timeout)
    clearTrigger()
    post('kipu_signout_cancel')
  }
  signOut.now = (reason) => {
    post('kipu_signout', { force: true, ...eventFrom(reason) })
  }

  const clearTrigger = () => {
    if (triggerSignout) window.removeEventListener('beforeunload', triggerSignout, { once: true })
    triggerSignout = null
  }

  if (isParentWindow) {
    const getCallbacks = () =>
      callbacks
        .map(({ onSignout }) => onSignout)
        .filter(Boolean)

    const setTrigger = (args, reason) => {
      clearTrigger()
      if (reason === 'user_changed') return
      triggerSignout = (ev) => {
        getCallbacks().forEach((handler) => {
          try {
            handler(...args)
          } catch (err) {
            logger.error(err)
          }
        })
        try {
          navigator.sendBeacon(`${logoutPath}?reason=${reason || 'user_logout'}`)
        } catch (err) {
          const request = new XMLHttpRequest()
          request.open('POST', `${logoutPath}?reason=${reason || 'user_logout'}`, false)
          request.send(null)
        }
      }
      return triggerSignout
    }

    const executeSignout = (args, reason) => {
      clearTrigger()

      if (reason === 'user_changed') return window.location.reload()

      if (isSignInPage()) return

      const waitFor = callbacks
        .map(({ onSignout }) => onSignout)
        .filter(Boolean)
        .map((onSignout) => Promise.resolve().then(() => onSignout(...args)).catch(_ => {}))
      Promise.all(waitFor).finally(() => {
        window.location.href = `${logoutPath}?reason=${reason || 'user_logout'}`
      })
    }
    usePostMessageListener((event) => {
      const { data } = event || {}
      if (!data) return
      if (data.type === 'kipu_signout') {
        try {
          if (isSigningOut.value === 'user_changed') throw new Error('User Changed')
          if (isSignInPage()) throw new Error('Cannot Signout from Signin Page')
          clearTimeout(timeout)
          isSigningOut.value = data.reason ?? 'user_logout'
          const notAuthorized = data.reason === 'user_not_authorized'
          const isUserChanged = data.reason === 'user_changed'
          const unskippable = notAuthorized || isUserChanged

          if (window.Kipu?.formIsDirty) window.Kipu.onFormInputChange()

          const args = data.args || []

          if (!unskippable && data.force) return executeSignout(args, data.reason)

          setTrigger(args, data.reason)

          const disallowed = !unskippable && callbacks.find(({ beforeSignout }) => beforeSignout?.(...args) === false)

          if (disallowed) throw new Error('Signout Aborted')

          if (!isUserChanged) window.addEventListener('beforeunload', triggerSignout, { once: true })

          timeout = setTimeout(() => executeSignout(args, data.reason), (notAuthorized ? 60 : 5) * 1000)
        } catch (err) {
          logger.error(err)
          signOut.cancel()
        }
      } else if (data.type === 'kipu_signout_cancel') {
        if (isSigningOut.value === 'user_changed') return false
        clearTimeout(timeout)
        clearTrigger()
        isSigningOut.value = null
        callbacks.forEach(({ onCancel }) => {
          try {
            onCancel?.(data)
          } catch (err) {
            logger.error(err)
          }
        })
      }
    })
  }

  window.document.addEventListener('click', (event) => {
    const isLogoutTrigger =
      (event.target.tagName === 'A' && /^\/users\/sign_out/.test(new URL(event.target.href, window.location.origin).pathname)) ||
      event.target.classList.contains('trigger-sign-out')
    if (isLogoutTrigger) {
      event.preventDefault()
      signOut({ reason: 'user_logout' })
    }
  })

  return signOut
}

export function useSignoutCall ({ defaultReason, beforeSignout, onSignout, onCancel }) {
  const signOut = buildSignout()

  callbacks.push({ beforeSignout, onSignout, onCancel })
  const proxy = (reason) => signOut(reason || defaultReason)
  Object.assign(proxy, signOut)
  return proxy
}
