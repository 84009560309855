<script setup>
import { defineAsyncComponent, ref, onMounted } from 'vue'
import { transformHashKeys } from '@/lib/utils/Casing'

const MarsMainCard = defineAsyncComponent(() => import('@/apps/mars/components/MarsManager/MarsMainCard'))
const patientId = ref(null)
const date = ref(null)
const siteSettings = ref(null)
const shouldCreateComponent = ref(false)
onMounted(() => {
  const target = document.getElementById('mars-manager-container')
  const targetDataset = transformHashKeys(target?.dataset)

  if (target) {
    shouldCreateComponent.value = true
    patientId.value = targetDataset.patientId
    date.value = targetDataset.date
    siteSettings.value = transformHashKeys(JSON.parse(targetDataset.siteSettings))
  }
})
</script>
<template>
  <Suspense v-if="shouldCreateComponent">
    <Teleport
      to="#mars-manager-container"
    >
      <MarsMainCard
        :patient-id="Number.parseInt(patientId)"
        :initial-date="date"
        :site-settings="siteSettings"
      />
    </Teleport>
  </Suspense>
</template>
